@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

@keyframes float {
  0%, 100% { transform: translateY(0) scale(1); }
  50% { transform: translateY(-20px) scale(1.05); }
}

.animate-float {
  animation: float 8s ease-in-out infinite;
}

.animate-float-delay {
  animation: float 8s ease-in-out infinite 2s;
}

.making {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.st {
    text-align: center;
    color: #000;
    font-size: 28px;
    padding: 10px;
}

.card {
    background: inherit;
    /* margin: 20px; */
    position: relative;
    width: 400px;
    height: 250px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    transition: 0.5s;
    border: none;
}

.card .circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
}

.card .circle::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
    clip-path: circle(80px at center);
    transition: 0.5s;
}

.card:hover .circle:before {
    background-image: linear-gradient(147deg, #000000 0%, #2c3e50 74%);
    clip-path: circle(400px at center);
}

.card img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    pointer-events: none;
    transition: 0.5s;
}

.card:hover img {
    bottom: 20px;
    left: 72%;
    width: 150px;
    opacity: 0;
}

.card .content {
    position: relative;
    /* width: 50%; */
    /* left: 20%; */
    padding: 20px;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}

.card:hover .content {
    left: 0;
    opacity: 1;
    visibility: visible;
}

.card .content h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 2em;
    line-height: 1em;
}

.card .content p {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card .content a {
    color: #fff;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 991px) {
    .card {
        width: auto;
        max-width: 400px;
        /* align-items: flex-start; */
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
    }
    .card:hover {
        height: 250px;
    }
    /* .card:hover img {
        height: 100px;
        width: 100px;
        top: 300px;
    } */
    .card .content {
        width: 100%;
        left: 0;
        padding: 60px;
    }
}

@media (max-width: 420px) {
    .card .content {
        width: 100%;
        padding: 20px;
    }
}


/* portfolio or gallery */
.portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto 25px auto;
    list-style: none;
    text-align: center;
    border-radius: 50px;
  }
  
  .portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
    font-family: "Poppins", sans-serif;
  }
  
  .portfolio #portfolio-flters li:hover,
  .portfolio #portfolio-flters li.filter-active {
    color: #e82d2d;
  }
  
  .portfolio #portfolio-flters li:last-child {
    margin-right: 0;
  }
  
  .portfolio .portfolio-item {
    margin-bottom: 30px;
  }
  
  .portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 0;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    background: rgba(255, 255, 255, 0.9);
    padding: 15px;
  }
  
  .portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    color: #191919;
  }
  
  .portfolio .portfolio-item .portfolio-info p {
    color: #4c4c4c;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link,
  .portfolio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 40px;
    font-size: 24px;
    top: calc(50% - 18px);
    color: #333333;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link:hover,
  .portfolio .portfolio-item .portfolio-info .details-link:hover {
    color: #cc1616;
  }
  
  .portfolio .portfolio-item .portfolio-info .details-link {
    right: 10px;
  }
  
  .portfolio .portfolio-item .portfolio-links {
    opacity: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 3;
    position: absolute;
    transition: all ease-in-out 0.3s;
  }
  
  .portfolio .portfolio-item .portfolio-links a {
    color: #fff;
    margin: 0 2px;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
  }
  
  .portfolio .portfolio-item .portfolio-links a:hover {
    color: #e82d2d;
  }
  
  .portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
    bottom: 20px;
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
  .portfolio-details {
    padding-top: 40px;
  }
  
  .portfolio-details .portfolio-details-slider img {
    width: 100%;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #cc1616;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #cc1616;
  }
  
  .portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(25, 25, 25, 0.08);
  }
  
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
  }
  
  .portfolio-details .portfolio-description {
    padding-top: 30px;
  }
  
  .portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .portfolio-details .portfolio-description p {
    padding: 0;
  }



  /* wings */

  .container{
    margin:10px 0px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    padding: 50px 0;
    transform-style: preserve-3d;
  }
  .contentBx p{
    font-size: 10px;
  }
  .img {
    padding-left: 17px;
    width: 444px;
    height: 299px;
    padding-bottom: 16px;
    padding-top: 12px;
  }
  .header {
    padding-bottom: 10px;
    font-size: 15px;
    text-align: center;
  }
  .container .box{
    position: relative;
    width: 450px;
    height: 300px;
    margin: 60px 0;
    background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
    transform-style: preserve-3d;
  }
  .container .box:hover{
    box-shadow: 0 50px 80px rgba(0,0,0,.2);
  }
  
  .container .box .imgBx{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .container .box .contentBx{
    position: absolute;
    top: 50%;
    left: 50px;
    right: 50px;
    background:#fff;
    transform: translateZ(20px) scaleY(0);
    padding: 40px 25px;
    transform-origin: top;
    transition: 0.5s;
    transform-style: preserve-3d;
  }
  
  .container .box:hover .contentBx{
    transform: translateZ(20px) scaleY(1);
  }

  @media screen and (max-width: 768px){
    .container{
      /* display: block; */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .container .box{
      width: 250px;

    }
    .container .imgBx{
      width: 300px;
    }
    /* .container .box:hover .contentBx{
      transform: translateZ(20px) scaleY(1);
    } */
  }
  @media screen and (max-width:360px){
    .container{
      display: block;
    }
    .container .contentBx{
      width: 200px;
      padding:10px
    }
    .container .box{
      width: 271px;
    }
    /* .container .box:hover .contentBx{
      transform: translateZ(20px) scaleY(0.5);
    } */
  }
  @media screen and (max-width: 300px){
    .container{
      display: block;

    }
    .container .box{
      width: 150px;
      padding:10px
    }
    .container .imgBx{
      width: 200px;
    }
  }

/* contactus  */


@import url('https://fonts.googleapis.com/css2?family=Poppins:200;300;400;500;600;700;800;900&display=swap');

/* .body{
    max-height: 80vh;
    background: linear-gradient(90deg,#0e3959 0%,#0e3959 30%,#03a9f5 30%,#03a9f5 100%);
} */
.contactUS{
    
    position: relative;
    width: 100%;
    padding: 40px 100px;
}
.contactUS .title h2{
    /* color: #fff; */
    font-weight: 500;
}
.form{
    grid-area: form;
}
.info{
    grid-area: info;
}
.map{
    grid-area: map;
}
.contact{
    margin:0px 10px;
    /* padding: 40px; */
    /* background: #fff; */
}
.box{
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 4fr 4fr;
    grid-template-areas: "form info" "form map";
    grid-gap: 20px;
    margin-top: 20px;
}
.contact h3{
    /* color: #0e3959; */
    font-weight: 500;
    font-size: 1.4rem;
    margin-bottom: 10px;
}
/*form*/
.formBox{
    position: relative;
    width: 100%;
}
.formBox .row50{
    display: flex;
    gap: 20px;
}
.inputBox{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 50%;

}
.formBox .row100 .inputBox{
    width: 100%;
}

.inputBox span{
    /* color:#03a9f5 ; */
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 500;
}

.inputBox input{
    padding: 10px;
    font-size: 1.1em;
    outline: none;
    border-radius: 10px;
    /* border: 1px solid #333; */
}

.inputBox textarea{
    padding: 10px;
    font-size: 1.1em;
    outline: none;
    /* border: 1px solid #333; */
    resize: none;
    min-height: 220px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.inputBox input[type="submit"]{
    background: #18b7ff;
    color: #fff;
    border: none;
    font-size: 1.1em;
    font-weight: 500;
    cursor: pointer;
    padding: 14px 15px;
}
.inputBox input[type="submit"]:hover{
  background: #4ab2e6;
}
 
/*info*/
/*.info h3{
    /* color: #fff; 
}*/
.info .infoBox div{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.info .infoBox div span{
    min-width: 40px;
    height: 40px;
    color: white;
    background: #18b7ff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    border-radius: 50%;
    margin-right: 15px;
}

.info .infoBox div p{
    color: #fff;
    font-size: 1.1em;
}
.info .infoBox div a{
    color: #fff;
    text-decoration: none;
    font-size: 1.1em;
}
.sci{
    margin-top:40px;
    display: flex;
}
.sci li{
    list-style: none;
    margin-right: 15px;
}
.sci li a{
    color: #fff;
    font-size: 2em;
}
/* .fb:hover{
    color: #4267B2;
}
.ln:hover{
    color: #0e76a8;
}
.in:hover{
    color:linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
}
.Ghub:hover{
    color:#000000
} */
.map{
    padding: 0;
}
.map iframe{
    width: 100%;
    height: 100%;
    border-radius: 10px;

}

/*RESPONSIVE*/
@media (max-width: 768px){
    .contactUS{
        padding: 20px;
    }
    .box{
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas: "form" "info" "map";
    }
    .map{
        min-height: 300px;
    }
    .formBox .row50{
        display: flex;
        gap: 0;
        flex-direction: column;
    }
    .inputBox{
        width: 100%;
    }
    .contact{
        /* padding: 30px; */
        /* display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; */
        
    }
    .map{
        min-height: 300px;
        padding: 0;
    }
}



  /* Gallery ............... */
  .gallery{
    -webkit-column-count: 3;
    -moz-column-count: 3;
     column-count: 3;
     -webkit-column-width: 33%;
     -moz-column-width: 33%;
     column-width: 33%;
     padding: 0 12px;
}

.gallery .pics{
    transition: all 350ms ease;
    -webkit-transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

.gallery .pics:hover{
    filter: opacity(.8);
}    
@media (max-width: 991px){
    .gallery{
        -webkit-column-count: 2;
        -moz-column-count: 2;
         column-count: 2;
    }
}

@media (max-width: 480px){
    .gallery{
        -webkit-column-count: 1;
        -moz-column-count: 1;
         column-count: 1;
         -webkit-column-width: 100%;
         -moz-column-width: 100%;
         column-width: 100%;
    }    
    .model.open{
      display: flex;
      background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
    }
}

/*model*/
.model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    transition:opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}
.model.open{
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}
.model img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}
.model.open svg{
    position: fixed;
    top: 80px;
    right: 20px;
    width: 6rem;
    height: 6rem;
    padding: 25px;
    background-color: rgba(0,0,0,0.4);
    color: #ffffff;
    cursor: pointer;
}



.section_padding{
  padding:4rem 4rem;
}

.footer{
  background-color: #192733;
  
}

.sb_footer{
  display:flex;
  flex-direction: column;
}

.sb_footer-links{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width:100%;
  text-align: left;
  margin-bottom: 2rem;
}



sb_footer-links_div{
  width: 150px;
  margin:1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  display: inline-block;
  vertical-align: top;
} 

.sb_footer-links{
  color: whitesmoke;
}

a{
  text-decoration: none;
  font-size: 20px;
}

h4{
  color: whitesmoke;
}

.underline{
  text-decoration:underline;
}

span{
  color:#18b7ff;
}

.social-icons{
  display: flex;
  gap:10px;
  font-size:50px;
}

.social-icons a {
  transition: transform 0.3s ease;
}

.social-icons a:hover{
  color:#18b7ff;
}

.sb_footer-links_div h4{
  font-size:20px;
  line-height: 17px;
  margin:0.5rem 0;
  cursor: pointer;
}

.footer-links{
  transition: color 0.4s ease;
  color: whitesmoke;
}

.footer-links:hover{
  color: #18b7ff;
}

.sb_footer-below{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0.2rem;
  color: whitesmoke;
}

hr{
  color:white !important;
  width:100%;
}
          
  
        /* Team */

    .profile-card{
          position: relative;
          width: 250px;
          height: 250px;
          background: lavender;
          padding: 30px;
          border-radius: 50%;
          box-shadow: 0px 5px 15px rgba(254, 255, 255, 0.495);
          transition: .6s;
      }
      .profile-card:hover{
          border-radius: 15px;
          height: 320px;
          /* padding-bottom: 20px; */
      }
      .profile-card .img{
          position: relative;
          left: -5px;
          width: 100%;
          height: 100%;
          transition: .6s;
          /* z-index: 999; */
      }
      .profile-card:hover .img{
          transform: translateY(-90px);
      }
      .img img{
          object-fit: cover;
                 width: 100%;
          height: 100%;
          border-radius: 100%;
          /* box-shadow: 0 0 22px #3336; */
          transition: .6s;
      }
      .profile-card:hover img{
          border-radius: 20px;
      }
      .caption{
          text-align: center;
          transform: translateY(-100px);
          opacity: 0;
          transition: .6s;
          display: flex;
          flex-direction: column;
          font-family: 'Courier New', Courier, monospace;
      }
      .caption h3{
          font-size: 19px;
         
      }
      .profile-card:hover .caption{
          opacity: 1;
      }
      .caption p{
          font-size: 17px;
          color: #0091ff;
          font-family: sans-serif;
          margin: 2px 0px 6px 0px;
      }
      .caption .social-links a{
          color:#333;
          margin-right:-8px;
          font-size: 24px;
          transition: .5s;
      }
      .social-links a:hover{
        color: #0077ff;
      }
      .social-links ul{
          display: flex;
          justify-content: center;
      }
      .social-links li{
          margin-left: 10px;
      }








      /* Testimonials */


      
.section{
    position: relative;
    width: 100%;
    height: fit-content;
    padding-bottom: 50px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background:linear-gradient(147deg, #000000 0%, #2c3e50 74%); ;
    overflow: hidden;
}
.swiper-container{
    height: 60%;
    position: relative;
    top: 5  0px;
    width: 60%;
    padding: 50px;
    padding-bottom: 50px;
}
.swiper-slide{
    background-position: center;
    background-size: cover;
    width: 32px;
    height: 10px;
    background: linear-gradient(315deg, #485461 0%, #28313b 74%);;
    box-shadow:0 0 0 white;
    filter: blur(4px);
    border-radius: 10px;
}
.swiper-slide-active{
    /* box-shadow:    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #0fa,
    0 0 82px rgb(25, 30, 28),
    0 0 92px rgb(25, 30, 28); */
    filter: blur(0px);
    background: linear-gradient(315deg, #485461 0%, #28313b 74%);;
}
.testimonialBox{
    height: 275px;
    position: relative;
    width: 100%;
    padding: 40px;
    padding-top: 90px;
    color: #f0e3e3;
}
.testimonialBox .quote{
    position: absolute;
    width: 48px;
    top: 24px;
    right: 30px;
    opacity: 0.2;
}
.testimonialBox .testimonialPara{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.testimonialBox .details{
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.testimonialBox .details .imgBox{
    position: relative;
    width: 150px;
    height: 150px;
    border-radius:100% ;
    overflow: hidden;
    margin-right: 10px;
    object-fit: contain;
}
.testimonialBox .details .imgBox img{
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.testimonialBox .details h3{
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #2196f3;
    line-height: 1.1em;
}
.testimonialBox .details h3 span{
    font-size: 12px;
    color:#666
}
.swiper-container-3d .swiper-slide-shadow-left{
    background-image: none;
}
.content {
}
@media screen and(max-width:990px) {
  .content{
    margin-top: -60px;
    margin-bottom: 10px;
  }
}
@media screen and(max-width:750px) {
  
}
@media (max-width: 800px){
    .swiper-container{
        width: 100%;
        height: 500px;
    }
}
@media (max-width: 600px){
    .swiper-container{
        width: 85%;
        display: block;
        height: 590px;
        padding-bottom: 25px;
        
    }
    .box{
        width: 100%;
        margin-bottom: 4%;
    }
}


/* footer */

.imgcard{
  background-color: #00000073;
  display: flex;
  align-items: center;
  
}

#footer {
    /* background: #0c0c0c; */
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
  }
  
  #footer .footer-top {
    padding: 60px 0 30px 0;
  }
  
  #footer .footer-top .footer-info {
    margin-bottom: 15px;
    background: #191919;
    color: #fff;
    border-top: 4px solid #cc1616;
    text-align: center;
    padding: 30px 20px;
  }
  
  #footer .footer-top .footer-info h3 {
    font-size: 36px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
  }
  
  #footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
  }
  
  #footer .footer-top .social-links a {
    font-size: 18px;
    /* display: ifle; */
    background: #262626;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .footer-top .social-links a:hover {
    background: #cc1616;
    color: #fff;
    text-decoration: none;
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #ed5b5b;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.2s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    color: #eb4444;
  }
  
  #footer .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4;
  }
  
  #footer .footer-top .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
  }
  
  #footer .footer-top .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #cc1616;
    color: #fff;
    transition: 0.1s;
    border-radius: 4;
  }
  
  #footer .footer-top .footer-newsletter form input[type=submit]:hover {
    background: #e82d2d;
  }
  
  #footer .copyright {
    border-top: 1px solid #262626;
    text-align: center;
    padding-top: 30px;
  }
  
  #footer .credits {
    padding-top: 5px;
    text-align: center;
    font-size: 13px;
    color: #fff;
  }
  
  #footer .credits a {
    color: #e82d2d;
  }
  
  @media (max-width: 575px) {
    #footer .footer-top .footer-info {
      margin: -20px 0 30px 0;
    }
  }

  /* new team card */
  .wrapper .img-area{
    box-shadow: inset -2px -2px 6px #cac6f5,
              inset 2px 2px 4px #cac6f5;

  }
/* .social-icons a, */
.buttons button{
  background:lavender;
  box-shadow: -1px -1px 3px #ffffff,
               1px 1px 2px #ceced1;
}

.wrapper{
  position: relative;
  width: 280px;
  height: 170PX;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  transition: 0.1S;
  border-radius: 10PX;
  background: rgba( 255, 255, 255, 0.1 );
  box-shadow: 0 8px 32px 0 rgba(76, 0, 137, 0.37);
  backdrop-filter: blur( 2px );
  -webkit-backdrop-filter: blur( 2px );
  border-radius: 10px;
  /* border: 1px solid rgba( 255, 255, 255, 0.18 ); */

}
.wrapper:hover{
  background:transparent;
  backdrop-filter: blur( 0px );
  -webkit-backdrop-filter: blur( 0px );
  box-shadow: none;
  border: none;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
}
.effect{
    position: absolute;
    width: 50px;
    height: 50px;
    padding: 30px;
    border-radius: 50%;
    transition: .4s;
    z-index: -1;
    opacity: 0;
    background: rgba(38, 38, 74, 0.15);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 1.5px );
    -webkit-backdrop-filter: blur( 1.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.wrapper:hover  .effect{
    border-radius: 10px;
    height: 412px;
    width: 300px;
    box-shadow: 0 20px 25px -5px #000, 0 8px 10px -6px #000;
    opacity: 1;
}

.wrapper .img-area{
  height: 220px;
  width: 220px;
  border-radius: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.img-area .inner-area{
  height: calc(100% - 25px);
  width: calc(100% - 25px);
  border-radius: 50%;
}
.inner-area img{
  height: 100%;
  width: 100%;
  border-radius: 10%;
  object-fit: cover;
  /* transition: 0.8s; */
}
.glass{
  background: rgba( 56, 88, 161, 0.1 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 2px );
-webkit-backdrop-filter: blur( 2px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.wrapper .name{
  font-size: 23px;
  font-weight: 500;
  color: #f7f7fb;
  margin: 10px 0 5px 0;
  transform: translateY(-80px);
  transition: all 0.5s;
  text-align: center;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
}
.wrapper:hover .name{
    transform: translateY(0px);
    visibility: visible;
    opacity: 1;
    
}

.wrapper .about{
  color: #ecedf8;
  font-weight: 400;
  font-size: 16px;
  transform: translateY(-120px);
  transition: 0.35s;
  text-align: center;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
}
.wrapper:hover .about{
    transform: translateY(0px);
    visibility: visible;
    opacity: 1;
}
.wrapper .secname{
    position: absolute;
    transform: translateY(55px);
    font-family: 'poppins',sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    transition: 0.4s;
}
.wrapper:hover .secname{
    transform: translateY(100px);
    opacity: 0;
}

.wrapper .social-icons{
  margin: 15px 0 25px 0;
  transform: translateY(-150px);
  transition: 0.4s;
  z-index: -1;
  visibility: collapse;
  opacity: 0;
  
}
.wrapper:hover .social-icons{
  transform: translateY(0px);
  visibility: visible;
  opacity: 1;
}
.social-icons a{
  position: relative;
  height: 48px;
  width: 48px;
  margin: 0 5px;
  display: inline-flex;
  text-decoration: none;
  border-radius: 50%;
  box-shadow: inset -2px -2px 6px #cac6f5,
  inset 2px 2px 4px #cac6f5;
  transition: all 0.3s ease;
}

.iconbeat{
 
  background: rgba(138, 7, 253, 0.3);
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 5.5px );
  height: 36px;
  width: 36px;
  -webkit-backdrop-filter: blur( 5.5px );
  border-radius: 50%;
  border: 1px solid rgba( 255, 255, 255, 0.28 );
  transition: all 0.7s ease;
}
.social-icons a:hover .iconbeat{
  transform: rotateY(360deg);
}



