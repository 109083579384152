@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    overflow-x: hidden;
}

@font-face {
  font-family: "BG";
  src: url("./assets/fonts/BricolageGrotesque.ttf");
}